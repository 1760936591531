@charset "utf-8";

/* ======= DOCUMENT ======= */
* {box-sizing: border-box;}
html,
body {
  width: 100%;
  height: 100%;
}
body {
  padding: 0;
  margin: 0;
}
/* ======= DOCUMENT 끝 ======= */
/* ======= ELEMENTS ======= */
main {display: block;}
ul {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}
img {
  max-width: 100%;
  border-style: none;
  vertical-align: middle;
}
a {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
}
p,
h1,
h2,
h3,
h4 {
  padding: 0;
  margin: 0;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
button {cursor: pointer;padding:0;}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size:100%;
  margin:0;
  color:inherit;
}
button,
select {text-transform: none;}
fieldset {padding: 0.35em 0.75em 0.625em;}
legend {
  padding: 0;
  color: inherit;
}
textarea {overflow: auto;}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* ======= ELEMENTS 끝 ======= */
/* ======= COMMON CLASS ======= */
.hide {
  position: absolute;
  left: -9999px;
  width: 0;
  height: 0;
  line-height: 0;
  padding: 0;
  margin: 0;
  border: 0;
  font-size: 0;
  text-indent: -9999px;
  text-shadow: none;
  background-color: transparent;
  color: transparent;
}
.overlay {
  position: fixed;
  display: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.5);
  z-index: 999;
}
.overlay.on {display: block;}

/* margin */
.mb10 {margin-bottom: 10px;}
.mb15 {margin-bottom: 15px;}
.mb20 {margin-bottom: 20px;}
.mb30 {margin-bottom: 30px;}
.mb40 {margin-bottom: 40px;}
.mb80 {margin-bottom: 80px;}
.mr5 {margin-right: 5px;}
.mr10 {margin-right: 10px;}
.mr15 {margin-right: 15px;}
.mr20 {margin-right: 20px;}
.mr30 {margin-right: 30px;}
/* ======= COMMON CLASS 끝 ======= */