@import "mixin";

/* ======= GLOBAL ======= */
/* color */
$defaultBlue: #4A7EFE;
$defaultGreen: #00BF8E;
$defaultRed: #FA4163;
$defaultLgray: #ccc;
$defaultDgray: #757575;
$defaultBg: #F1F1F2;
$defaultBg2: #F5F7F9;

/* font */
$defaultTitle: 20px;
$defaultLgBtn: 17px;
$defaultSubject: 16px;
$defaultBody: 15px;
$defaultSm: 13px;
$defaultWarn: 11px;

/* radius */
$defaultRadius: 10px;

/* nav */
$defaultNav: 300px;
/* ======= GLOBAL 끝 ======= */

/* ======= COMMON ======= */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap');

*::placeholder {
  font-size: $defaultSubject;
  color:$defaultLgray;
}
html, body {
  font-family: 'Noto Sans KR',sans-serif;
  font-size: $defaultBody;
}
fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}
input {
  width: 100%;
  padding: 10px;
  border: 0;
}
select, input[type="checkbox"], input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
button {border:0;background-color: #fff;font-weight: bold;}
h2 {font-size: 20px;}
h3 {font-size: 17px;}
h4 {font-size: 15px;}
small {font-size: 13px;}
.colblue {color:$defaultBlue;}
.colgreen {color: $defaultGreen;}
.colred {color: $defaultRed;}
.coldg {color: $defaultDgray;}
.undline {text-decoration: underline;}
.nowrap {white-space: nowrap;}
.td_notice {
  font-weight: bold;
  color:$defaultGreen;
}
.required {
  &::after {
    content:"*";
    display: inline-block;
    padding-left: 5px;
    font-size: inherit;
    color:$defaultGreen;
  }
}

/* 버튼 */
.btn_green {
  width: 100px;
  padding: 8px 0;
  background-color: $defaultGreen;
  color:#fff;
}
.btn_green_line {
  width: 100px;
  padding: 6px 0;
  border: 2px solid $defaultGreen;
  background-color: #fff;
  color:$defaultGreen;
}
.btn_blue_line {
  width: 100px;
  padding: 6px 0;
  border: 2px solid $defaultBlue;
  background-color: #fff;
  color:$defaultBlue;
}
.btn_loadRewards {
  width: 100%;
  padding: 8px 0;
  background-color: $defaultGreen;
  font-size: $defaultSubject;
  color:#fff;
}
.btn_remove {
  width: 28px;
  min-width: 28px;
  height: 28px;
  background: url(../assets/img/img_delete.png) no-repeat center/contain;
}
.btn_search_lg {
  width: 40px;
  min-width: 40px;
  height: 40px;
  background: url(../assets/img/img_search.png) no-repeat center/contain;
}
.btn_add_wrap {
  position: relative;
  width: 100%;
  height: 40px;
  &::after {
    content:"";
    position: absolute;
    @include yCenter;
    display: inline-block;
    width: 100%;
    height: 1px;
    border-top:1px dashed #000;
  }
  .btn_add_option {
    display: none;
    position: absolute;
    @include xCenter;
    width: 250px;
    height: 40px;
    z-index: 1;
    button {
      width: 50%;
      height: 100%;
      background-color: $defaultBlue;
      color:#fff;
      &:nth-child(2n) {background-color: $defaultGreen;}
    }
  }
  &:hover {
    .btn_add {display: none;}
    .btn_add_option {@include flex;}
  }
}
.btn_add {
  position: absolute;
  @include xCenter;
  width: 40px;
  height: 40px;
  background: url(../assets/img/img_plus.png) no-repeat center/contain #f4f4f8;
  z-index: 1;
}
.btn_add_sm {
  width: 28px;
  min-width: 28px;
  height: 28px;
  background: url(../assets/img/img_add_sm.png) no-repeat center/contain #fff;
}
.btn_close {
  width: 14px;
  height: 14px;
  vertical-align: middle;
  background: url(../assets/img/img_close.png) no-repeat center/contain;
}
/* 버튼 끝 */

/* 인픗 */
.input_default {
  border-bottom: 1px solid $defaultLgray;
}

.input_address_wrap {
  margin-bottom: 15px;
  label {
    display: block;
    margin-bottom: 10px;
  }
  input {margin-bottom: 15px;}
  .input_postcode {
    @include flexBetween;
    column-gap: 5px;
  }
  .input_postcode button {
    width: 100%;
    height: 50px;
    max-width: 80px;
    border-radius: $defaultRadius;
    background-color: $defaultDgray;
    font-weight: bold;
    color:#fff;
  }
}

.chk_default {
  width: 25px;
  min-width: 25px;
  height: 25px;
  vertical-align: middle;
  background: url(../assets/img/img_chk.png) no-repeat center/25px;
  &:checked {background: url(../assets/img/img_chked.png) no-repeat center/25px;}
}
.chk_circle {
  width: 25px;
  height: 25px;
  vertical-align: middle;
  border-radius: 50%;
  background: url(../assets/img/img_chk_circle.png) no-repeat center/contain;
  &:checked {background: url(../assets/img/img_chked_circle.png) no-repeat center/contain;}
}
.select_default {
  width: 100%;
  padding: 10px;
  border: 0;
  border-bottom: 1px solid $defaultLgray;
  background: url(../assets/img/arrow_down_gray.png) no-repeat calc(100% - 8px) center/10px;
}
.select_sm {
  width: 68px;
  padding: 5px 10px;
  font-size: $defaultSm;
  border:1px solid #C4C4C4;
  background: url(../assets/img/arrow_down_gray.png) no-repeat calc(100% - 5px) center/10px;
}
.textarea_default {
  width: 100%;
  border: 1px solid $defaultLgray;
  resize: horizontal;
}
/* 인풋 끝 */

/* 테이블 */
.table_default {
  width: 100%;
  border-top: 1px solid $defaultLgray;
  border-bottom: 1px solid $defaultLgray;
  text-align: center;
  th, td {
    padding: 16px 5px;
  }
  tr {
    td:first-of-type,
    th:first-of-type {padding-left: 90px;}
    td:last-of-type,
    th:last-of-type {padding-right: 90px;}
  }
  thead th {border-bottom: 1px solid $defaultLgray;}
  .left {text-align: left;}
}
/* 테이블 끝 */


/* ======= COMMON 끝 ======= */


/** 인덱스 **/
.cms_index_wrap {
  display: grid;
  width: 100%;
  height: 100vh;
  grid-template-columns: 1fr 1fr;
  user-select: none;
  & > div {
    @include flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .cms_index__left {
    background-image: linear-gradient(to bottom, #00BF8E, #4A7EFE);
    color:#fff;
    h1 {margin-bottom: 50px;}
    p {
      font-size: 16px;
      letter-spacing: -1.5px;
    }
  }
  .cms_index__right {
    text-align: left;
    .inner {
      width: 50%;
      margin: 0 auto;
    }
    h3 {margin-bottom: 30px;}
    fieldset {margin-bottom: 30px;}
    label {
      display: block;
      width: 100%;
      margin-bottom: 10px;
    }
    a, button {
      display: block;
      width: 100%;
      padding: 8px 0;
      text-align: center;
      background-color: $defaultBlue;
      font-size: 16px;
      color:#fff;
    }
  }
}
/** 인덱스 **/

/** 헤더 **/
#header {
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $defaultGreen;
  color:#fff;
  z-index: 99;
  .header_inner {
    @include flexBetween;
    height: 100%;
    padding: 0 20px;
    align-items: center;
    h1 {
      width: 100%;
      max-width: 125px;
      margin-top: -10px;
      img {
        width: 100%;
      }
    }
  }
  .header_inner_right {
    span {
      margin-right: 20px;
    }
    a, button {
      display: inline-block;
      padding: 5px;
      border: 1px solid #fff;
    }
  }
}
/** 헤더 끝 **/

/** 내비게이션 **/
#gnb_wrap {
  position: fixed;
  top: 70px;
  left: 0;
  width: $defaultNav;
  height: calc(100% - 70px);
  background-color: #fff;
  box-shadow: 0 3px 4px rgba(0,0,0,.15);
  .gnb {
    padding-top: 30px;
    ul {
      li {
        width: 100%;
        a {
          display: block;
          width: 100%;
          padding:20px 0 20px 70px;
          border-left: 4px solid transparent;
          font-weight: bold;
          font-size: 16px;
          background-repeat: no-repeat;
          background-size: 20px;
          background-position: 25px center;
          background-image: url(../assets/img/icon_home.png);
          &:hover, &.active {
            border-left: 4px solid $defaultBlue;
            background-color: rgba(0, 98, 255,.05);
            background-image: url(../assets/img/icon_home_blue.png);
          }
        }
        &:nth-of-type(2) a {
          background-image: url(../assets/img/icon_member.png);
        &:hover, &.active {background-image: url(../assets/img/icon_member_blue.png);}
        }
        &:nth-of-type(3) a {
          background-image: url(../assets/img/icon_solo.png);
        &:hover, &.active {background-image: url(../assets/img/icon_solo_blue.png);}
        }
        &:nth-of-type(4) a {
          background-image: url(../assets/img/icon_team.png);
        &:hover, &.active {background-image: url(../assets/img/icon_team_blue.png);}
        }
        &:nth-of-type(5) a {
          background-image: url(../assets/img/icon_stamp.png);
        &:hover, &.active {background-image: url(../assets/img/icon_stamp_blue.png);}
        }
        &:nth-of-type(6) a {
          background-image: url(../assets/img/icon_spots.png);
        &:hover, &.active {background-image: url(../assets/img/icon_spots_blue.png);}
        }
        &:nth-of-type(7) a {
          background-image: url(../assets/img/icon_rewards.png);
        &:hover, &.active {background-image: url(../assets/img/icon_rewards_blue.png);}
        }
        &:nth-of-type(8) a {
          background-image: url(../assets/img/icon_board.png);
        &:hover, &.active {background-image: url(../assets/img/icon_board_blue.png);}
        }
        &:nth-of-type(9) a {
          background-image: url(../assets/img/icon_board.png);
        &:hover, &.active {background-image: url(../assets/img/icon_board_blue.png);}
        }
        &:nth-of-type(10) a {
          background-image: url(../assets/img/icon_term.png);
        &:hover, &.active {background-image: url(../assets/img/icon_term_blue.png);}
        }
      }
    }
  }
}
/** 내비게이션 끝 **/

/* ======= 페이지 ======= */
/* 공통 박스 */
.continer_full {width: 100%;}
.container_adm {
  padding-top: 70px;
  padding-left: $defaultNav;
  width: 100%;
  height: auto;
  .container_adm_inner {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 60px;
    background-color: #f4f4f8;
  }
}
.content_box {
  width: 100%;
  padding: 50px 95px;
  background-color: #fff;
}
.flex_wrap {
  @include flex;
  flex-wrap:wrap;
  column-gap:5%;
  row-gap:30px;
  .one-third {
    @include flex;
    width: 30%;
    align-items: center;
    & > label {
      width: 130px;
      min-width: 130px;
      font-weight: bold;
    }
  }
  .two-third {
    @include flex;
    width: 65%;
    align-items: center;
    & > label {
      width: 130px;
      min-width: 130px;
      font-weight: bold;
    }
  }
}
.flex_wrap2 {
  flex-direction: column;
  .three-third {
    @include flex;
    & > label {
      width: 130px;
      min-width: 130px;
      font-weight: bold;
    }
  }
}
.one-third {width: 33.33333%;}
.two-third {width: 66.66666%;}
.three-third {width: 100%;}

.box_green {
  display: inline-block;
  width: auto;
  height: 28px;
  line-height: 28px;
  padding: 0 10px;
  text-align: center;
  background-color: $defaultGreen;
  color:#fff;
}
.box_green_line {
  display: inline-block;
  width: auto;
  height: 28px;
  line-height: 26px;
  padding: 0 10px;
  text-align: center;
  border: 1px solid $defaultGreen;
  color: $defaultGreen;
}
.paging {
  @include flex;
  justify-content: center;
  align-items: center;
  .paging_arrow {
    width: 42px;
    height: 42px;
    background: url(../assets/img/arrow_left_gray.png) no-repeat center/7px;
  }
  .paging_end {background: url(../assets/img/arrow_right_gray.png) no-repeat center/7px;}
  .paging_num {
    width: 42px;
    height: 42px;
    font-size: $defaultSubject;
    font-weight: bold;
    background: #fff;
    color:#aaa;
    &:hover, &.active {
      // border-bottom: 3px solid $defaultBlue;
      // color:$defaultBlue;
    }
  }
}
.btns_wrap {
  @include flex;
  column-gap: 20px;
  justify-content: center;
}
.term_editor {
  width: 100%;
  border: 1px solid $defaultLgray;
  min-height: 190px;
}
/* 공통 박스 끝 */

/* 기본설정 */
.adm_title {
  font-size: 34px;
  font-weight: bold;
}
.content_box {
  & > h2 {padding-bottom: 25px;}
}
/* 기본설정 끝 */

/* 회원관리 */
.adm_title_wrap {
  @include flexBetween;
  padding: 60px 60px 0 60px;
  align-items: center;
  .adm_title__left {
    @include flex;
    column-gap: 10px;
    align-items: flex-end;
  }
  .adm_title__right {
    @include flex;
    column-gap: 10px;
    select {
      min-width: 98px;
      height: 48px;
      padding: 0 20px;
      border: 1px solid $defaultLgray;
      background: url(../assets/img/arrow_down_gray.png) no-repeat calc(100% - 10px) center/14px #fff;
    }
    .search_wrap {
      @include flexBetween;
      width: 100%;
      min-width: 250px;
      max-width: 300px;
      height: 48px;
      line-height: 46px;
      border: 1px solid $defaultLgray;
      input {
        width: 80%;
        height: 100%;
      }
      .btn_search {
        width: 20%;
        height: 100%;
        background: url(../assets/img/icon_search.png) no-repeat center/24px;
      }
    }
  }
}
.memb_id_cate {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 14px;
  vertical-align: middle;
  &[data-cate="kk"] {background: url(../assets/img/img_kakao.png) no-repeat center/contain;}
  &[data-cate="fb"] {background: url(../assets/img/img_facebook.png) no-repeat center/contain;}
  &[data-cate="ap"] {background: url(../assets/img/img_apple.png) no-repeat center/contain;}
  &[data-cate="nv"] {background: url(../assets/img/img_naver.png) no-repeat center/contain;}
}
.memb_history_wrap {
  width: 100%;
  min-height: 190px;
  padding: 20px;
  border: 1px solid $defaultLgray;
  overflow-y: auto;
  ul {
    li {
      @include flexBetween;
      margin-bottom: 10px;
      .memb_history__left {
        span:first-of-type{display: inline-block; width: 140px;}
      }
      .memb_history_result {
        display: inline-block;
        width: 64px;
        height: 28px;
        line-height: 28px;
        font-size: $defaultSm;
        text-align: center;
        background-color: $defaultRed;
        color:#fff;
        &[data-cate="fail"] {background-color: #000;}
      }
    }
  }
}
.memb_tit_extra {
  display:inline-block;
  padding-left:20px;
  font-size:$defaultBody;
  color:$defaultGreen;
}
/* 회원관리 끝 */

/* 미션 */
.adm_title_sm {
  font-size: 24px;
  font-weight: bold;
}
.ms_datepicker {
  @include flex;
  column-gap: 10px;
  align-items: center;
  input[type="text"] {
    position: relative;
    max-width: 175px;
    border-bottom: 1px solid $defaultLgray;
    background: url(../assets/img/img_pickdate.png) no-repeat calc(100% - 10px) center/16px;
  } 
}
.file_upload_wrap {
  position: relative;
  width: 100%;
  height: 42px;
  margin-right: 20px;
  border-bottom: 1px solid $defaultLgray;
  & > span {
    position: absolute;
    display: inline-block;
    padding-left: 10px;
    height: 40px;
    line-height: 40px;
    background-color: #fff;
    color:$defaultLgray;
    z-index: 2;
  }
  label {
    position: absolute;
    width: 100%;
    height: 100%;
    input {
      &::-webkit-file-upload-button {
        position: absolute;
        @include yCenter;
        right: 10px;
        width: 70px;
        height: 28px;
        padding: 0;
        border:0;
        font-size: $defaultSm;
        text-align: center;
        background-color: #525253;
        color:#fff;
      }
    }
  }
}
.ms_loaded_reward_wrap {
  @include flex;
  column-gap: 20px;
  text-align: center;
  .loaded_reward__box {
    position: relative;
    border: 1px solid $defaultLgray;
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
    img {
      position: absolute;
      @include Center;
      width: 100%;
    }
    .btn_remove {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  small {
    display: inline-block;
    max-width: 100px;
    word-break: keep-all;
  }
}
.ms_step1_info {
  text-align: center;
  strong {
    display: inline-block;
    padding-right: 20px;
  }
  span {display: inline-block;
  padding-right: 50px;}
}
.mission_box {
  position: relative;
  @include flexBetween;
  flex-wrap: wrap;
  border:1px solid $defaultBlue;
  .mission_box__left {
    width: 120px;
  }
  .mission_box__right {
    width: calc(100% - 220px);
    & > div {margin-bottom: 30px;}
    .right_label {
      width: 130px;
      min-width: 130px;
      font-weight: bold;
    }
    .right_line1 {
      @include flex;
      & > label {
        width: 100%;
        max-width: 220px;
        margin-right: 50px;
      }
      & > button {margin-right: 30px;}
      & > input {margin-right: 10px;}
    }
    .right_line2 {
      & > label {
        display: block;
        margin-bottom: 20px;
      }
    }
    .right_line3 {
      @include flexBetween;
      align-items: center;
    }
    .right_line4 {
      @include flexBetween;
      align-items: baseline;
      .ms_type0_a_wrap {
        width: calc(100% - 130px);
        .chk_default {margin-right: 20px;}
        strong {
          display: inline-block;
          margin-right: 40px;
          color: $defaultBlue;
        }
        .type0_a_item {
          @include flex;
          width: 100%;
          margin-bottom: 20px;
          align-items: center;
          .input_default {margin-right: 20px;}
        }
        .type0_a_item_last {
          strong {color: $defaultLgray;}
        }
      }
    }
    .right_line5 {
      @include flexBetween;
      .ms_type0_hint {
        width: calc(100% - 130px);
        input:not(:last-child) {margin-bottom: 20px;}
      }
    }
  }
  .btn_close {
    position: absolute;
    top: 30px;
    right: 30px;
  }
}
.mission_box2, .mission_box3, .mission_box4 {
  .mission_box__right {
    .right_line3 {
      justify-content: flex-start;  
    }
    .right_line4 {
      & > div {
        @include flex;
        align-items: center;
        width: calc(100% - 130px);
        select {max-width: 280px;}
      }
    }
  }
}
.mission_box4 {
  .mission_box__right {
    .right_line4 {
      align-items: flex-start;
      div {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
#form_missionteamAdd {
  .flex_wrap {
    .one-third, .two-third {
      & > label {
        width: 150px;
        min-width: 150px;
      }
    }
  }
}
/* 미션 끝 */

/* 스탬프 */
.stamp_character_wrap {
  @include flexBetween;
  width: 100%;
  & > label {
    width: 130px;
    min-width: 130px;
    font-weight: bold;
  }
  .stamp_img_wrap {
    @include flex;
    width: calc(100% - 130px);
    align-items: flex-start;
    label {
      position: relative;
      display: block;
      width: 100px;
      height: 100px;
      &:not(:last-child) {margin-right: 20px;}
      input {
        position: absolute;
        width: 100%;
        height: 100%;
        border: 1px solid $defaultLgray;
        &:checked {border: 2px solid $defaultGreen;}
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.stamp_line1,
.stamp_line2,
.stamp_line3,
.stamp_line4 {
  @include flex;
  align-items: center
}
.stamp_line2 {
  .two-third {
    @include flex;
    column-gap: 10px;
  }
}
.stamp_line4 {align-items: flex-start;}
/* 스탬프 끝*/


/* 광주 관광지 */
.spots_address_wrap {
  @include flex;
  width: 100%;
}
/* 광주 관광지 끝*/

/* 광주 관광지 */
.rewards_list_wrap {
  @include flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 40px 95px;
  row-gap: 40px;
  border-top: 1px solid $defaultLgray;
  border-bottom: 1px solid $defaultLgray;
  .reward_item {
    width: calc(100% / 6 - 20px);
    margin-right: 20px;
    label {
      position: relative;
      display: block;
      width: 100%;
      height: 150px;
      margin-bottom: 20px;
      border: 1px solid $defaultLgray;
      input {
        position: absolute;
        top: -1px;
        left: -1px;
      }
      img {width: 100%;height: 100%;object-fit: contain;}
      p {max-width: 225px;}
    }
  }
}
/* 광주 관광지 끝*/

/* ======= 페이지 끝 ======= */

/* ======= 모달 ======= */
.modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0,0,0,.7);
  .modal_wrap {
    position: relative;
    display: flex;
    width: auto;
    min-height: calc(100% - (0.5rem * 2));
    align-items: center;
    justify-content: center;
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transition: transform 0.3s ease-out;
    -webkit-transition: -webkit-transform 0.3s ease-out;
    -o-transition: -o-transform 0.3s ease-out;
  }
  .modal_content {
    position: relative;
    width: 100%;
    max-width: 495px;
    background-color: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
    z-index: 9999;
    &.modal_lg {max-width: 800px;}
    .modal_header {
      padding: 20px 15px 15px;
      @include flex;
      justify-content: right
    }
    form .modal_body {
      @include flex;
      min-height: 150px;
      padding: 15px;
      align-items: center;
      flex-direction: column;
      text-align: center;
    }
    .modal_footer {
      width: 100%;
      padding: 20px 0;
      border-top: 1px solid $defaultLgray;
      text-align: center;
    }
    .modal_search_wrap {
      @include flexBetween;
      width: 280px;
      column-gap: 18px;
      margin: 0 auto 30px;
      align-items: center;
    }
  }
}
#modalRewards {
  .modal_header {
    @include flexBetween;
    width: 100%;
    height: 58px;
    padding: 0 30px;
    align-items: center;
    background-color: $defaultBlue;
    color:#fff;
    .btn_close {
      background: url(../assets/img/img_close_wh.png) no-repeat center/contain;
    }
  }
  .modal_body {
    padding: 30px;
  }
  .modalRewards_wrap {
    @include flex;
    flex-wrap: wrap;
    padding: 30px 0;
    margin-bottom: 20px;
    row-gap:20px;
    border-top: 1px solid #000;
    border-bottom: 1px solid $defaultLgray;
    .modalReward {
      margin-right: 20px;
      &:nth-of-type(6n) {margin-right: 0;}
      label {
        position: relative;
        display: block;
        width: 100px;
        height: 100px;
        margin-bottom: 10px;
        input {
          position: absolute;
          width: 100%;
          height: 100%;
          border: 1px solid $defaultLgray;
          &:checked {border: 1px solid $defaultGreen;}
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    small {
      display: inline-block;
      max-width: 100px;
      text-align: center;
      word-break: keep-all;
      -ms-word-break: keep-all;
    }
  }
}
/* ======= 모달 끝 ======= */

/* ======= 텍스트 에디터 ======= */
.ck.ck-editor {
  width: 100%;
  .ck-content {min-height: 150px;}
}
/* ======= 텍스트 에디터 끝 ======= */

.input_warn {
  padding-top: 10px;
  color: $defaultRed;
  font-size: $defaultWarn;
  img {
    width: 18px;
    height: 18px;
  }
}

.two-third {
  .input_wrap {
    width: 100%;
  }
}

/*** 페이징 ***/
.paging {
  text-align: center;
}

.paging li {
  display: inline;
}

.paging .paging_first,
.paging .paging_end {
  display: inline-block;
  width: 12px;
  height: 18px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.paging .paging_first {
  position: relative;
  top: 3px;
  background-image: url(../assets/img/icon_paging-first.svg);
}

.paging .paging_end {
  position: relative;
  top: 3px;
  background-image: url(../assets/img/icon_paging-end.svg);
}

.paging .paging_num {
  display: inline-block;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
}

.paging .paging_current {
  color: #00BF8E;
}

.backdrop{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #fff;
  opacity: 0.9;

  display: flex;           /* establish flex container */
  flex-direction: column;  /* make main axis vertical */
  justify-content: center; /* center items vertically, in this case */
  align-items: center;     /* center items horizontally, in this case */
}
